import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Link,
  styled,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Airtable from "airtable";
import clsx from "clsx";
import { CopyBlock } from "react-code-blocks";
import "./App.css";

import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Widget } from "@typeform/embed-react";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ReactComponent as LinkedInLogo } from "./linkedin.svg";
import { ReactComponent as TwitterLogo } from "./twitter.svg";

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_PAT }).base(
  "appx9a4sZKhhLR7PW"
);

const theme = createTheme({
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: 32,
      fontWeight: 900,
    },
    h2: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
    },
    body2: {
      fontSize: 10,
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: "#09c184",
    },
    text: {
      primary: "#202020",
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  ".MuiDataGrid-cell": {
    fontSize: "12px",
  },
  ".MuiDataGrid-columnHeaderTitle": {
    fontSize: "12px",
  },
  ".description-cell": {
    padding: "5px 10px",
  },
  ".table-cell-truncate": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "initial",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

interface TableRow {
  id: string;
  rank: number;
  repo: string;
  repo_name: string;
  description: string;
  category: string;
  score: number;
  star_count: number;
  star_cadence: string;
  contributors: number;
  commits: number;
  watchers_count: number;
  watchers_delta: number;
  release_cadence: string;
  forks: number;
  languages: string;
  license: string;
  created_at: string;
}

interface LanguageRow {
  id: string;
  name: string;
  count: number;
}

interface LicenceRow {
  id: string;
  name: string;
  count: number;
}

function App() {
  const [rows, setRows] = useState<Array<TableRow>>([]);
  const [languageRows, setLanguageRows] = useState<Array<LanguageRow>>([]);
  const [licenceRows, setLicenceRows] = useState<Array<LicenceRow>>([]);
  const [categories, setCategories] = useState<
    Array<{ label: string; count: number; percentage: number }>
  >([]);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const columnWidth = matches ? 110 : 90;
  const tableHeight = matches ? "100%" : "660px";

  useEffect(() => {
    const recordRows: Array<TableRow> = [];
    const categoriesArray: Array<string> = [];
    base("Summary")
      .select({
        view: "Grid view",
        maxRecords: 100,
        sort: [{ field: "score", direction: "desc" }],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.

          records.forEach(function (record, index) {
            categoriesArray.push(record.get("category") as string);
            recordRows.push({
              id: record.getId() as string,
              rank: index + 1,
              repo: record.get("repo") as string,
              repo_name: record.get("repo_name") as string,
              description: record.get("description") as string,
              category: record.get("category") as string,
              score: parseFloat(record.get("score") as string),
              star_count: parseInt(record.get("star_count") as string),
              star_cadence: parseFloat(
                record.get("star_cadence") as string
              ).toFixed(2),
              contributors: parseInt(record.get("contributors") as string),
              commits: parseInt(record.get("commits") as string),
              watchers_count: parseInt(record.get("watchers_count") as string),
              watchers_delta: record.get("watchers_delta") as number,
              release_cadence: parseFloat(
                record.get("release_cadence") as string
              ).toFixed(2),
              forks: parseFloat(record.get("forks") as string),
              languages: record.get("languages") as string,
              license: record.get("license") as string,
              created_at: record.get("created_at") as string,
            });
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          setRows([...recordRows]);

          const count: { [key: string]: number } = {};

          for (const element of categoriesArray) {
            if (count[element]) {
              count[element] += 1;
            } else {
              count[element] = 1;
            }
          }

          setCategories(
            Object.entries(count)
              .map(([key, value]) => ({
                label: key,
                count: value,
              }))
              .sort((a, b) => b.count - a.count)
              .map((item) => ({
                ...item,
                percentage: parseFloat(
                  ((item.count / categoriesArray.length) * 100).toFixed(1)
                ),
              }))
              .slice(0, 5)
          );
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  useEffect(() => {
    const recordRows: Array<LanguageRow> = [];
    base("Languages")
      .select({
        view: "Grid view",
        maxRecords: 20,
        sort: [{ field: "count", direction: "desc" }],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.

          records.forEach(function (record) {
            recordRows.push({
              id: record.getId() as string,
              name: record.get("name") as string,
              count: record.get("count") as number,
            });
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          setLanguageRows([
            ...recordRows
              .filter(
                (r) =>
                  ![
                    "Shell",
                    "Dockerfile",
                    "Makefile",
                    "HTML",
                    "CSS",
                    "PowerShell",
                    "Batchfile",
                  ].includes(r.name)
              )
              .slice(0, 5),
          ]);
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  useEffect(() => {
    const recordRows: Array<LicenceRow> = [];
    base("Licenses Info")
      .select({
        view: "Grid view",
        maxRecords: 5,
        sort: [{ field: "count", direction: "desc" }],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.

          records.forEach(function (record) {
            recordRows.push({
              id: record.getId() as string,
              name: record.get("name") as string,
              count: record.get("count") as number,
            });
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          setLicenceRows([...recordRows]);
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  const columns: GridColDef[] = [
    {
      field: "rank",
      headerName: "Rank",
      type: "number",
      width: 50,
      align: "center",
      headerAlign: "left",
      disableColumnMenu: true,
    },
    {
      field: "repo_name",
      headerName: "Repo",
      width: matches ? 120 : 110,
      renderCell: (params) => (
        <Link
          underline="always"
          target="_blank"
          href={params.row.repo}
          rel="noreferrer"
          color="#202020"
          variant="body1"
          style={{ fontWeight: 700, fontSize: "12px" }}
        >
          {params.row.repo_name}
        </Link>
      ),
    },
    {
      field: "score",
      headerName: "Index Score",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      width: matches ? 150 : 120,
      align: "left",
      headerAlign: "center",
      cellClassName: () => clsx("description-cell"),
      renderCell: (params) =>
        params.row.description ? (
          <Tooltip enterTouchDelay={0} title={params.row.description}>
            <span className="table-cell-truncate">
              {params.row.description}
            </span>
          </Tooltip>
        ) : undefined,
    },
    {
      field: "category",
      headerName: "Category",
      type: "string",
      width: matches ? 150 : 120,
      align: "left",
      headerAlign: "center",
      cellClassName: () => clsx("category-cell"),
      renderCell: (params) =>
        params.row.category ? (
          <Tooltip enterTouchDelay={0} title={params.row.category}>
            <span className="table-cell-truncate">{params.row.category}</span>
          </Tooltip>
        ) : undefined,
    },
    {
      field: "star_count",
      headerName: "Stars",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "star_cadence",
      headerName: "Star Cadence",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contributors",
      headerName: "Contributors",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "watchers_count",
      headerName: "Watchers",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "watchers_delta",
      headerName: "Watcher Delta",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value * 100).toFixed(2);
        return `${valueFormatted} %`;
      },
    },
    {
      field: "commits",
      headerName: "Commits",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "release_cadence",
      headerName: "Release Cadence",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "forks",
      headerName: "Forks",
      type: "number",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created_at",
      headerName: "Created At",
      type: "string",
      width: columnWidth,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "languages",
      headerName: "Languages (Top 3)",
      type: "string",
      width: columnWidth,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "license",
      headerName: "License",
      type: "string",
      width: columnWidth,
      align: "left",
      headerAlign: "center",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ background: "#FCFCFC", paddingTop: theme.spacing(8) }}>
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              }}
            >
              <Typography
                textAlign="center"
                variant="h1"
                style={{ marginBottom: theme.spacing(4) }}
              >
                Open Source{" "}
                <span
                  style={{
                    backgroundColor: "#09c184",
                    color: "#FFFFFF",
                    padding: theme.spacing(0.5),
                    paddingTop: theme.spacing(0.25),
                    marginLeft: theme.spacing(-0.5),
                    marginRight: theme.spacing(-0.5),
                  }}
                >
                  Security
                </span>{" "}
                Index
              </Typography>
              <Typography
                textAlign="center"
                variant="h2"
                style={{ marginBottom: theme.spacing(2) }}
              >
                The Most{" "}
                <span
                  style={{
                    backgroundColor: "#09c184",
                    color: "#FFFFFF",
                    padding: theme.spacing(0.25),
                    marginLeft: theme.spacing(-0.25),
                    marginRight: theme.spacing(-0.25),
                  }}
                >
                  Popular
                </span>{" "}
                & Fastest{" "}
                <span
                  style={{
                    backgroundColor: "#09c184",
                    color: "#FFFFFF",
                    padding: theme.spacing(0.25),
                    marginLeft: theme.spacing(-0.25),
                    marginRight: theme.spacing(-0.25),
                  }}
                >
                  Growing
                </span>{" "}
                Open Source Security{" "}
                <span
                  style={{
                    backgroundColor: "#09c184",
                    color: "#FFFFFF",
                    padding: theme.spacing(0.25),
                    marginLeft: theme.spacing(-0.25),
                    marginRight: theme.spacing(-0.25),
                  }}
                >
                  Projects
                </span>{" "}
                on GitHub
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                marginTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
              }}
            >
              <div
                style={{
                  padding: theme.spacing(2),
                  filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1))",
                  backgroundColor: "#FFFFFF",
                  borderRadius: theme.spacing(1),
                  height: tableHeight,
                }}
              >
                <StripedDataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "score", sort: "desc" }],
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  disableSelectionOnClick
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                  getRowHeight={() => "auto"}
                />
              </div>

              {/* <iframe
                className="airtable-embed"
                src="https://airtable.com/embed/shrhxQGSII6XzRQy4?backgroundColor=gray&layout=card&viewControls=on"
                frameBorder="0"
                width="100%"
                height="600"
                title="os-security-index"
                style={{ background: "transparent", border: "1px solid #ccc" }}
              ></iframe> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                marginTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
              }}
            >
              <div
                style={{
                  padding: theme.spacing(2),
                  filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1))",
                  backgroundColor: "#FFFFFF",
                  borderRadius: theme.spacing(1),
                }}
              >
                <Typography textAlign="left" variant="h4">
                  Top languages
                </Typography>
                <Chart
                  options={{
                    chart: {
                      type: "bar",
                      toolbar: {
                        show: false,
                      },
                      height: 550,
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 4,
                        horizontal: true,
                        distributed: true,
                        dataLabels: {
                          position: "bottom",
                        },
                      },
                    },
                    colors: ["#09c184"],
                    dataLabels: {
                      enabled: true,
                      textAnchor: "start",
                      offsetX: -10,
                      style: {
                        colors: ["#fff"],
                      },
                      formatter: function (val, opt) {
                        return val + "%";
                      },
                      dropShadow: {
                        enabled: false,
                      },
                    },
                    tooltip: {
                      enabled: false,
                      shared: false,
                    },
                    xaxis: {
                      labels: { show: false },
                      categories: languageRows.map((l) => l.name),
                    },
                    yaxis: {
                      show: true,
                      labels: {
                        show: true,
                      },
                    },
                    legend: {
                      show: false,
                    },
                  }}
                  series={[
                    {
                      data: languageRows.map((l) =>
                        parseFloat((l.count * 100).toFixed(1))
                      ),
                    },
                  ]}
                  type="bar"
                />
              </div>
              <div
                style={{
                  padding: theme.spacing(2),
                  filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1))",
                  backgroundColor: "#FFFFFF",
                  borderRadius: theme.spacing(1),
                  marginTop: theme.spacing(2),
                }}
              >
                <Typography textAlign="left" variant="h4">
                  License usage
                </Typography>
                <Chart
                  options={{
                    chart: {
                      type: "donut",
                      toolbar: {
                        show: false,
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      style: {
                        colors: ["#FFF"],
                      },
                      background: {
                        enabled: true,
                        foreColor: "#000",
                        opacity: 1,
                        padding: 4,
                        borderRadius: 4,
                        borderWidth: 0,
                        dropShadow: { enabled: false, opacity: 0 },
                      },
                      dropShadow: {
                        enabled: false,
                      },
                    },
                    tooltip: {
                      enabled: false,
                      shared: false,
                    },
                    legend: {
                      show: true,
                      position: "bottom",
                      customLegendItems: licenceRows.map((l) => l.name),
                    },
                  }}
                  series={licenceRows.map((l) => l.count)}
                  labels={licenceRows.map((l) => l.name)}
                  type="donut"
                />
              </div>
              <div
                style={{
                  padding: theme.spacing(0),
                  filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1))",
                  backgroundColor: "#FFFFFF",
                  borderRadius: theme.spacing(1),
                  marginTop: theme.spacing(2),
                }}
              >
                <div
                  style={{
                    padding: theme.spacing(2),
                    paddingBottom: theme.spacing(0),
                  }}
                >
                  <Typography textAlign="left" variant="h4">
                    Top categories
                  </Typography>
                </div>
                <div
                  style={{
                    paddingRight: theme.spacing(1),
                    marginLeft: theme.spacing(-1),
                  }}
                >
                  <Chart
                    options={{
                      chart: {
                        type: "bar",
                        toolbar: {
                          show: false,
                        },
                        height: 650,
                      },
                      plotOptions: {
                        bar: {
                          borderRadius: 4,
                          horizontal: true,
                          distributed: true,
                          dataLabels: {
                            position: "bottom",
                          },
                        },
                      },
                      colors: ["#09c184"],
                      dataLabels: {
                        enabled: true,
                        textAnchor: "start",
                        offsetX: 0,
                        style: {
                          colors: ["#fff"],
                        },
                        formatter: function (
                          val,
                          { seriesIndex, dataPointIndex, w }
                        ) {
                          return w.config.xaxis.categories[dataPointIndex];
                        },
                        dropShadow: {
                          enabled: false,
                        },
                      },
                      tooltip: {
                        theme: "dark",
                        x: {
                          show: false,
                        },
                        y: {
                          title: {
                            formatter: function (name: string) {
                              return "";
                            },
                          },
                          formatter: function (
                            val,
                            { seriesIndex, dataPointIndex, w }
                          ) {
                            return w.config.xaxis.categories[dataPointIndex];
                          },
                        },
                      },
                      xaxis: {
                        labels: { show: false },
                        categories: categories.map((l) => l.label),
                      },
                      yaxis: {
                        show: true,
                        labels: {
                          show: true,
                          maxWidth: 60,
                          formatter: function (val, opt) {
                            return opt
                              ? `${
                                  opt.w.config.series[0].data[
                                    opt.dataPointIndex
                                  ]
                                }%`
                              : "%";
                          },
                        },
                      },
                      legend: {
                        show: false,
                      },
                    }}
                    series={[
                      {
                        data: categories.map((l) => l.percentage),
                      },
                    ]}
                    type="bar"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(4),
            }}
          >
            <Typography
              textAlign="center"
              variant="h5"
              style={{ marginBottom: theme.spacing(4) }}
            >
              The Open Source Security Index is designed to make finding open
              source security projects easier for everyone. We use the Github
              API to pull projects based on popular security topics (# tags) and
              manually add projects without labelled topics. This is a live
              project evolving with the help of the open source security
              community, please share feedback including anything we have left
              out at{" "}
              <Link
                underline="always"
                target="_blank"
                href="https://twitter.com/OSecurityIndex"
                rel="noreferrer"
                color="#202020"
                variant="h5"
              >
                @OSecurityIndex
              </Link>
            </Typography>
            <Accordion
              style={{
                filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1))",
                backgroundColor: "#FFFFFF",
                borderRadius: theme.spacing(1),
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography textAlign="left" variant="h2">
                  How is the{" "}
                  <span
                    style={{
                      backgroundColor: "#09c184",
                      color: "#FFFFFF",
                      padding: theme.spacing(0.25),
                      marginLeft: theme.spacing(-0.25),
                      marginRight: theme.spacing(-0.25),
                    }}
                  >
                    Index Score
                  </span>{" "}
                  calculated?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  textAlign="left"
                  variant="body1"
                  style={{
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(3),
                  }}
                >
                  Our Index ranks open source security projects on Github using
                  what we call the “Index Score”. The score is a weighted
                  average of key metric's retrieved monthly from Github, which
                  we normalized to a scale of 0 to 100.
                </Typography>
                <Typography variant="h4">
                  <ol>
                    <li>Stars: 15%</li>
                    <li>
                      Star Cadence: 15%{" "}
                      <Typography component="span" variant="body1">
                        - the number of stars a project has had since it was
                        created (stars/days)
                      </Typography>
                    </li>
                    <li>
                      Contributors: 25%{" "}
                      <Typography component="span" variant="body1">
                        - we filter out bot and anonymous accounts
                      </Typography>
                    </li>
                    <li>
                      Release cadence: 25%{" "}
                      <Typography component="span" variant="body1">
                        - the number of commits a project has had over the last
                        12 months (commits/day)
                      </Typography>
                    </li>
                    <li>Watchers: 10%</li>
                    <li>
                      Watcher growth: 5%{" "}
                      <Typography component="span" variant="body1">
                        - the delta in Watchers over the last month
                      </Typography>
                    </li>
                    <li>Forks: 5%</li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              marginTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
            }}
          >
            <div
              style={{
                padding: theme.spacing(2),
                filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1))",
                backgroundColor: "#FFFFFF",
                borderRadius: theme.spacing(1),
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                textAlign="left"
                variant="h2"
                style={{ marginBottom: theme.spacing(2) }}
              >
                Take our{" "}
                <span
                  style={{
                    backgroundColor: "#09c184",
                    color: "#FFFFFF",
                    padding: theme.spacing(0.25),
                    marginLeft: theme.spacing(-0.25),
                    marginRight: theme.spacing(-0.25),
                  }}
                >
                  survey
                </span>
              </Typography>
              <Widget
                id="XHbkJ3jI"
                style={{ height: "550px", display: "block" }}
                className="my-form"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              marginTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
            }}
          >
            <div
              style={{
                padding: theme.spacing(2),
                filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1))",
                backgroundColor: "#FFFFFF",
                borderRadius: theme.spacing(1),
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                textAlign="left"
                variant="h2"
                style={{ marginBottom: theme.spacing(2) }}
              >
                Badge
              </Typography>
              <Typography
                textAlign="left"
                variant="body1"
                style={{
                  marginTop: theme.spacing(0),
                  marginBottom: theme.spacing(3),
                }}
              >
                Add the Open Source Security Index badge to your own website.
                This is a quick and easy way to let your site visitors know
                you’ve been featured as one of the fastest-growing open source
                security projects
              </Typography>
              <Link
                href="https://opensourcesecurityindex.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: "282px", height: "56px" }}
                  src="https://opensourcesecurityindex.io/badge.svg"
                  alt="Open Source Security Index - Fastest Growing Open Source Security Projects"
                  width="282"
                  height="56"
                />
              </Link>
              <CopyBlock
                text={`<a
    href="https://opensourcesecurityindex.io/"
    target="_blank"
    rel="noopener"
>
    <img
        style="width: 282px; height: 56px"
        src="https://opensourcesecurityindex.io/badge.svg"
        alt="Open Source Security Index - Fastest Growing Open Source Security Projects"
        width="282"
        height="56"
    />
</a>`}
                language="html"
                theme="codepen"
                showLineNumbers={false}
                wrapLines
                customStyle={{ display: "flex" }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: theme.spacing(2),
              paddingTop: theme.spacing(0),
              paddingBottom: theme.spacing(4),
              borderTopWidth: "1px",
              borderColor: "#DCDCDC",
              borderTopStyle: "solid",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 64,
                }}
              ></div>
              <Typography textAlign="center" variant="body2">
                © opensourcesecurityindex.io 2023. All Rights Reserved.
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: 64,
                  justifyContent: "space-between",
                }}
              >
                <Link
                  href="https://twitter.com/OSecurityIndex"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "30px", display: "flex" }}
                >
                  <TwitterLogo />
                </Link>
                <Link
                  href="https://www.linkedin.com/in/andrew-smyth-3a1078b4/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "30px", display: "flex" }}
                >
                  <LinkedInLogo />
                </Link>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
